var sl = [
 {
   "Have_it": true,
   "Item": "Anchovies",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Pastas",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Coffee",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Olive oil",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Vegetable oil",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Kosher salt",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Pepper",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Chicken stock",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Gelatin",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Dijon mustard",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Garlic",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Onion",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Wine",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Vinegar",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Beans",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Chickpeas",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Tomatoes",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Artichoke hearts",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Tomato paste",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Red pepper flakes",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Tuna",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Soy sauce",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Hot sauce",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Rice",
   "Section": "Pantry"
 },
 {
   "Have_it": true,
   "Item": "Coffee filters",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Better than Bouillon",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Coconut milk",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Baking Soda",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Flour",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Yeast",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Cereal",
   "Section": "Pantry",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Paper towels",
   "Section": "Other"
 },
 {
   "Have_it": true,
   "Item": "Garbage bags",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Dish soap",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Dishwasher pods",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Ziplock bags",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Vac seal bags",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Sponges",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Magic erasers",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Gloves",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Mop head",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Lactose aid",
   "Section": "Other",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Grano",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Carrots",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Celery",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Milk",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Butter",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Eggs",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Mayonnaise",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Ketchup",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Yogurt",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Sour cream",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Coke Zero",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Beer",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Parmesan",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Pecorino",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Cheddar",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Mozzarella",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "American cheese",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Ham",
   "Section": "Fridge"
 },
 {
   "Have_it": true,
   "Item": "Pepperoni",
   "Section": "Fridge",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Frozen broccoli",
   "Section": "Freezer"
 },
 {
   "Have_it": true,
   "Item": "Frozen peas",
   "Section": "Freezer"
 },
 {
   "Have_it": true,
   "Item": "Hand soap",
   "Section": "Bathroom",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Deodorant",
   "Section": "Bathroom",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Toothpaste",
   "Section": "Bathroom",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "TP",
   "Section": "Bathroom",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Mouthwash",
   "Section": "Bathroom",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Shampoo",
   "Section": "Bathroom",
   "Rare": true
 },
 {
   "Have_it": true,
   "Item": "Sunscreen",
   "Section": "Bathroom"
 },
 {
   "Have_it": true,
   "Item": "Shower soap",
   "Section": "Bathroom",
   "Rare": true
 }
]

export {sl}