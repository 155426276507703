<template>
  <div class="hello">
    <div class="table-responsive">
      <button @click="filterit('Common')" class="btn btn-primary">Common</button>
      <button @click="filterit('Rare')" class="btn btn-primary">Rare</button>
      <button @click="filterit('No')" class="btn btn-primary">No</button>
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">Have it</th>
              <th scope="col">Item</th>
              <th scope="col">Section</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in shopl" :key=item>
              <td v-if="item.Have_it"><button @click="toggle(item)" class="btn btn-primary">Yes</button></td>
              <td v-else><button @click="toggle(item)" class="btn btn-primary">No</button></td>
              <td>{{ item.Item }}</td>
              <td>{{ item.Section }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  </div>
</template>

<script>
var _ = require('lodash');
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
    sl: Array
  },
  data() {
    return {
      shopl: this.sl,
      unfilteredShoppingList: []
    }
  },
  async mounted() {
    // var s = await (await fetch('/get_sl')).json();
    var sl = require('../assets/list')
    this.unfilteredShoppingList = sl.sl

    
    this.shopl = this.unfilteredShoppingList
  },
  methods: {
    filterit(x) {
      if (x === 'Common') {
        this.shopl = _.filter(this.unfilteredShoppingList, function(o) {return o.Have_it});
        this.shopl = _.filter(this.unfilteredShoppingList, function(o) {return !o.Rare});
      }
      if (x === 'No') {
        this.shopl = _.filter(this.unfilteredShoppingList, function(o) {return !o.Have_it})
      }
      if (x === 'Rare') {
        this.shopl = _.filter(this.unfilteredShoppingList, function(o) {return o.Rare})
        console.log(this.shopl)
      }
    },
    toggle(item) {
      if (item.Have_it) { 
        item.Have_it = false 
      } else { item.Have_it = true }
      // this.update_sl()
    },
    update_sl() {
      console.log(this.unfilteredShoppingList)
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/update_sl', true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(
          this.unfilteredShoppingList
      ));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
